import React from "react";
import {AiOutlineArrowLeft} from 'react-icons/ai'
import {FaPhoneAlt} from "react-icons/fa"
import './success.less'

export default () => {
    return(
        <section className="success">
            <div>
                <h2>Successfully Submitted</h2>
                <p>Thank you for contacting us! We will get in touch with you shortly.</p>
                <a href="https://injury.yadgarovlaw.com/"> <AiOutlineArrowLeft/> Back To Our Page</a>
                <a href="tel:718-276-2800" className="success-cta"><span><FaPhoneAlt /></span> 718-276-2800</a>
            </div>
        </section>
    )
};